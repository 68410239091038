import {Component} from "react";

function limit(value, min, max) {
    return Math.min(Math.max(value, min), max)
}

export class ResizeComponent extends Component<{ className: string, onMove: (dx: number, dy: number)=>void, onResized: ()=>void }> {
    render() {
        return <div ref={el => this.element = el}
            className={this.props.className}
            onMouseDown={this.onMouseDown}
        />
    }

    onMouseDown = (e) => {
        e.stopPropagation()
        e.preventDefault()

        this.setState({x: e.clientX, y: e.clientY})
        window.addEventListener('mousemove', this.onMouseMove)
        window.addEventListener('mouseup', this.onMouseUp)
    }

    onMouseMove = (e) => {
        e.stopPropagation()
        e.preventDefault()

        // Ограничиваем координаты областью изображения
        const img = this.element.parentElement.parentElement
        const rect = img.getBoundingClientRect()
        const cx = limit(e.clientX, rect.x, rect.right)
        const cy = limit(e.clientY, rect.y, rect.bottom)

        const {x, y} = this.state
        this.props.onMove(x - cx, y - cy)
        this.setState({x: cx, y: cy})
    }

    onMouseUp = () => {
        window.removeEventListener('mousemove', this.onMouseMove)
        window.removeEventListener('mouseup', this.onMouseUp)
        this.props.onResized()
    }
}
