import {Route, Routes} from "react-router-dom";
import {RootPage} from "./RootPage";
import {MediaPage} from "./media/MediaPage";
import {MarkRootPage} from "./mark/MarkRootPage";
import {ClassesPage} from "./classes/ClassesPage";
import {InvitesPage} from "./invites/InvitesPage";

function NotFound() {
    return <div>Not found</div>
}

export function AuthSwitch() {
    return <>
        <Routes>
            <Route path="/" element={<RootPage/>}/>
            <Route path="media/*" element={<MediaPage/>}/>
            <Route path="mark/*" element={<MarkRootPage/>}/>
            <Route path="classes/*" element={<ClassesPage/>}/>
            <Route path="invites/*" element={<InvitesPage/>}/>
            <Route path="*" element={<NotFound/>}/>
        </Routes>
    </>
}