import {Component} from "react";
import {Link} from "react-router-dom";
import {Container} from "react-bootstrap";
import {Button, FileInput, FormGroup, Intent, ProgressBar} from "@blueprintjs/core";
import {IconNames} from "@blueprintjs/icons";
import api from "../../api/Api";
import {toast} from "../../AppToaster";
import LogoutButton from "../../components/LogoutButton";

export class MediaUploadPage extends Component {
    state = {
        uploading: false,
        progress: 0,
        file: null,
    }

    render() {
        const {uploading, file} = this.state

        return <Container className="p-3">
            <div className="toolbar">
                <Link to="/media">
                    <Button icon={IconNames.ARROW_LEFT}
                            text="Назад"
                            intent={Intent.PRIMARY}
                    />
                </Link>
                <LogoutButton/>
            </div>
            <div>
                <FormGroup label="Загрузка обучающего материала">
                    <FileInput
                        fill
                        text={file ? file.name : "Выберите файл..."}
                        onInputChange={this.selectFile}
                        buttonText="Выбрать"
                    />
                    {uploading && <ProgressBar value={this.state.progress} intent={Intent.PRIMARY}/>}
                    <Button onClick={this.upload} disabled={!file}>ЗАГРУЗИТЬ</Button>
                </FormGroup>
            </div>
        </Container>
    }

    selectFile = (event) => {
        const file = event.target.files[0]
        this.setState({
            file: file,
        });
    }

    upload = () => {
        const {file} = this.state

        this.setState({
            uploading: true,
        });

        api.media.upload(file, this.onProgress)
            .then(this.onUploaded)
            .finally(() => this.setState({uploading: false}))
    }

    onProgress = (event) => {
        this.setState({
            progress: event.loaded / event.total
        });
    }

    onUploaded = () => {
        toast('Загружено')
        this.setState({
            file: null,
        });
    }
}