import {useLocation, useNavigate, useParams} from "react-router-dom";
import {NavigateFunction} from "react-router-dom";

export type RouteProps = {
    location: Location,
    history: NavigateFunction,
    params: {[string]: string}
}

export default function withRouter(Children) {
    return (props) => {
        const location = useLocation()
        const history = useNavigate()
        const params = useParams()
        return <Children {...props} history={history} location={location} params={params}/>
    }
}