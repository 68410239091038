import {Component} from "react";
import api from "../../api/Api";
import {Icon, Spinner} from "@blueprintjs/core";
import DeleteButton from "../../components/DeleteButton";
import {formatDateTime} from "../../utils";
import type {IMedia} from "../../model/IMedia";
import {toast} from "../../AppToaster";
import {IconNames} from "@blueprintjs/icons";

type Sate = {
    files: IMedia[]
}

function Preview(props: { media: IMedia }) {
    const {media} = props
    if (media.hasPreview)
        return <img alt="preview" src={`/api/media/${media.id}/preview`}/>
    return <Icon icon={IconNames.MEDIA} style={{opacity: 0.5}} size={64}/>
}

export class MediaList extends Component<{}, Sate> {
    state: Sate = {
        files: null
    }

    componentDidMount() {
        api.media.list().then((files) => this.setState({files}))
    }

    render() {
        const {files} = this.state

        if (!files) return <Spinner/>

        return <>
            <ul className="list-unstyled">
                {files.map(f => <li className="media mb-4" key={f.id}>
                    <div className="preview mr-3">
                        <a href={`/api/media/${f.id}`} target="_blank" rel="noreferrer">
                            <Preview media={f}/>
                        </a>
                    </div>
                    <div className="media-body">
                        <h5 className="mt-0 mb-1">{f.fileName} <DeleteButton callback={() => this.deleteMedia(f)}/></h5>
                        <p>{f.uploadUser} {formatDateTime(f.date)}</p>
                    </div>
                </li>)}
            </ul>
        </>
    }

    deleteMedia(media: IMedia) {
        api.media.delete(media)
            .then(() => {
                const {files} = this.state
                const i = files.indexOf(media)
                if (i < 0) return
                files.splice(i, 1)
                this.setState({files})

                toast('Удалено')
            })
    }
}