import {Component} from "react";
import {Button, Intent, Spinner} from "@blueprintjs/core";
import api from "../../api/Api";
import type {IFrame} from "../../model/IFrame";
import type {IObjectClass} from "../../model/IObjectClass";
import type {IAnnotation} from "../../model/IAnnotation";
import {ResizeComponent} from "./ResizeComponent";
import {IconNames} from "@blueprintjs/icons";
import withRouter from "../../withRouter";
import type {RouteProps} from "../../withRouter";
import './MarkControl.scss'
import {Link} from "react-router-dom";

const Colors = [
    ['red', 'black'],
    ['blue', 'white'],
    ['green', 'black'],
    ['yellow', 'black'],
]

type State = {
    classes: IObjectClass[],
    classById: { [id: string]: IObjectClass },
    selectedClass: IObjectClass,
    frame: IFrame,
    width: number,
    draw: boolean,
    valid: boolean,
    x1: number,
    y1: number,
    x2: number,
    y2: number,
    annotations: IAnnotation[],
    selected: IAnnotation,
}

class MarkControl extends Component<{ frameId: string } & RouteProps, State> {
    state: State = {
        classes: null,
        classById: null,
        frame: null,
        width: 0,
        draw: false,
        valid: false,
        x1: null,
        y1: null,
        x2: null,
        y2: null,
        annotations: [],
        selected: null,
    }

    componentDidMount() {
        Promise.all([
            api.classes.list(),
            api.frames.get(this.props.frameId),
            api.mark.forFrame(this.props.frameId)
        ]).then(([classes: IObjectClass[], frame: IFrame, annotations: IAnnotation[]]) => {
            const classById = classes.reduce((a, c) => {
                a[c.id] = c
                return a
            }, {})

            for (let i = 0; i < classes.length; i++) {
                classes[i].color = Colors[i][0]
                classes[i].textColor = Colors[i][1]
            }

            for (const a of annotations) {
                a.class = classById[a.classId]
            }

            const selectedClass = classes.length > 0 ? classes[0] : null

            this.setState({classes, classById, selectedClass, frame, annotations})
        })
            .catch(() => {
                this.props.history('/mark')
            })

        window.addEventListener('resize', this.onResize)
        window.addEventListener('mouseup', this.onMouseUp)
        window.addEventListener('keydown', this.onKeyDown)
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.img && this.state.width === 0) {
            this.setState({width: this.img.clientWidth})
        }
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.onResize)
        window.removeEventListener('mouseup', this.onMouseUp)
        window.removeEventListener('keydown', this.onKeyDown)
    }

    onResize = () => {
        if (this.state.width !== this.img.clientWidth !== this.img.clientHeight) {
            this.setState({width: this.img.clientWidth})
        }
    }

    render() {
        const {classes, selectedClass, frame, width, annotations, selected} = this.state
        if (!frame) return <Spinner/>

        const url = `/api/frames/${frame.id}/image`
        const r = frame.width / frame.height
        const height = width / r

        return <>
            <div className="toolbar">
                <Link to="/">
                    <Button icon={IconNames.ARROW_LEFT}
                            text="Назад"
                            intent={Intent.PRIMARY}
                            className="mr-4"
                    />
                </Link>
                {classes.map(c => <Button key={c.id}
                                          text={c.name}
                                          className="mr-2"
                                          icon={c === selectedClass ? IconNames.EDIT : null}
                                          style={{backgroundColor: c.color, color: c.textColor}}
                                          onClick={() => this.setState({selectedClass: c})}
                />)}
                <div className="right-align">
                    {!!selected && <Button icon={IconNames.TRASH} intent={Intent.DANGER} className="mr-2" onClick={this.deleteSelected} />}
                    <Button intent={Intent.PRIMARY} text="Завершить" onClick={this.submit}/>
                </div>
            </div>
            <div
                ref={el => this.img = el}
                className="frame noselect"
                style={{
                    backgroundImage: `url(${url})`,
                    height: height,
                }}
                onMouseDown={this.onMouseDown}
                onMouseMove={this.onMouseMove}
            >
                {this.state.draw && <div
                    className='annotation-draw'
                    style={{
                        left: Math.min(this.state.x1, this.state.x2),
                        top: Math.min(this.state.y1, this.state.y2),
                        width: Math.abs(this.state.x1 - this.state.x2),
                        height: Math.abs(this.state.y1 - this.state.y2),
                    }}
                />}

                {annotations.map((a) =>
                    <div key={a.id}
                         className="annotation"
                         style={{
                             left: `${a.x * 100}%`,
                             top: `${a.y * 100}%`,
                             width: `${a.w * 100}%`,
                             height: `${a.h * 100}%`,
                             borderColor: a.class?.color,
                         }}
                         onClick={() => this.setState({selected: a})}
                    >
                        {/*<span style={{backgroundColor: a.class?.color, color: a.class?.textColor}}>{a.class?.code}</span>*/}
                        {a === selected && (<>
                            <div className="selection"/>
                            <ResizeComponent className="resize resize-tl"
                                             onMove={(dx, dy) => this.resizeAnnotation(a, dx, dy, dx, dy)}
                                             onResized={() => this.saveAnnotation(a)}/>
                            <ResizeComponent className="resize resize-tr"
                                             onMove={(dx, dy) => this.resizeAnnotation(a, 0, dy, -dx, dy)}
                                             onResized={() => this.saveAnnotation(a)}/>
                            <ResizeComponent className="resize resize-bl"
                                             onMove={(dx, dy) => this.resizeAnnotation(a, dx, 0, dx, -dy)}
                                             onResized={() => this.saveAnnotation(a)}/>
                            <ResizeComponent className="resize resize-br"
                                             onMove={(dx, dy) => this.resizeAnnotation(a, 0, 0, -dx, -dy)}
                                             onResized={() => this.saveAnnotation(a)}/>
                        </>)}
                    </div>
                )}
            </div>
        </>
    }

    resizeAnnotation(a: IAnnotation, dx: number, dy: number, dw: number, dh: number) {
        const width = this.img.clientWidth
        const height = this.img.clientHeight

        a.x = a.x - dx / width
        a.y = a.y - dy / height
        a.w += dw / width
        a.h += dh / height
        this.setState({annotations: this.state.annotations})
    }

    saveAnnotation(ann: IAnnotation) {
        api.mark.save(ann).then()
    }

    onMouseDown = (e) => {
        if (e.button === 0) {
            const rect = this.img.getBoundingClientRect()
            const x = e.clientX - rect.x
            const y = e.clientY - rect.y
            this.setState({x1: x, y1: y, x2: x, y2: y, draw: true, valid: false})
        }
    }

    onMouseMove = (e) => {
        const {draw, x1, y1} = this.state
        if (e.buttons === 1) {
            if (draw) {
                const rect = this.img.getBoundingClientRect()
                const x = e.clientX - rect.x
                const y = e.clientY - rect.y
                const valid = Math.abs(x1 - x) >= 16 && Math.abs(y1 - y) >= 16
                this.setState({x2: x, y2: y, valid})
            }
        } else if (draw) {
            this.stopDraw()
        }
    }

    onMouseUp = () => {
        this.stopDraw()
    }

    stopDraw() {
        if (!this.img) return
        if (!this.state.draw) return

        this.setState({draw: false})
        if (!this.state.valid) return

        const {frame, x1, y1, x2, y2, selectedClass} = this.state
        const width = this.img.clientWidth
        const height = this.img.clientHeight

        const x = Math.min(x1, x2) / width
        const y = Math.min(y1, y2) / height
        const w = Math.abs(x1 - x2) / width
        const h = Math.abs(y1 - y2) / height

        api.mark.create(frame.id, selectedClass.id, x, y, w, h)
            .then(a => {
                a.class = selectedClass
                const {annotations} = this.state
                annotations.push(a)
                this.setState({annotations})
            })
    }

    onKeyDown = (e) => {
        const {selected} = this.state
        if (selected && e.key === "Delete") {
            this.deleteSelected()
        }
    }

    deleteSelected = () => {
        const {selected} = this.state
        this.deleteAnnotation(selected)
    }

    deleteAnnotation(ann: IAnnotation) {
        api.mark.delete(ann)
            .then(() => {
                const {annotations} = this.state
                const i = annotations.indexOf(ann)
                if (i < 0) return
                annotations.splice(i, 1)
                this.setState({annotations})
                if (ann === this.state.selected) {
                    this.setState({selected: null})
                }
            })
    }

    submit = () => {
        api.frames.submit(this.props.frameId)
            .then(() => {
                this.props.history('/mark')
            })
    }
}

export default withRouter(MarkControl)
