import {Component} from "react";
import {Link} from "react-router-dom";
import {Button, Intent, Spinner} from "@blueprintjs/core";
import {IconNames} from "@blueprintjs/icons";
import LogoutButton from "../../components/LogoutButton";
import {Container, Table} from "react-bootstrap";
import api from "../../api/Api";
import type {IInvite} from "../../model/IInvite";
import {formatDateTime} from "../../utils";
import {toast} from "../../AppToaster";

type State = {
    invites: IInvite[]
}

export class InvitesPage extends Component<{}, State> {
    state: State = {
        invites: null
    }

    componentDidMount() {
        api.invites.list().then((invites) => this.setState({invites}))
    }

    render() {
        const {invites} = this.state

        return <Container className="p-3">
            <div className="toolbar">
                <Link to="/">
                    <Button icon={IconNames.ARROW_LEFT}
                            text="Назад"
                            intent={Intent.PRIMARY}
                    />
                </Link>
                <Button className="ml-3"
                        icon={IconNames.PLUS}
                        text="Добавить"
                        intent={Intent.NONE}
                        onClick={this.createInvite}
                />
                <LogoutButton/>
            </div>
            {!invites ? <Spinner/> : (
                <Table>
                    <tbody>
                    {invites.map((i) => <tr key={i.id}>
                        <td>{i.code}</td>
                        <td><Button icon={IconNames.CLIPBOARD} minimal onClick={() => this.copyLink(i)}/></td>
                        <td>{i.userCreated}</td>
                        <td>{formatDateTime(i.dateCreate)}</td>
                        <td>{i.userActivated}</td>
                        <td>{formatDateTime(i.dateActivate)}</td>
                        <td><Button icon={IconNames.TRASH} intent={Intent.DANGER} minimal onClick={() => this.deleteInvite(i)}/></td>
                    </tr>)}
                    </tbody>
                </Table>
            )}
        </Container>
    }

    createInvite = () => {
        api.invites.create().then((invite) => {
            this.state.invites.unshift(invite)
            this.setState({invites: this.state.invites})
        })
    }

    deleteInvite(invite: IInvite) {
        api.invites.delete(invite.id)
            .then(() => {
                const i = this.state.invites.indexOf(invite)
                this.state.invites.splice(i, 1)
                this.setState({invites: this.state.invites})
            })
    }

    copyLink(invite: IInvite) {
        navigator.clipboard.writeText(`${window.location.origin}/invite/${invite.code}`)
            .then(() => toast('Ссылка скопирована'))
    }
}