import {Component} from "react";
import {Link} from "react-router-dom";
import {Container} from "react-bootstrap";
import {Button, Intent} from "@blueprintjs/core";
import {IconNames} from "@blueprintjs/icons";
import LogoutButton from "../../components/LogoutButton";
import {CreateClass} from "./CreateClass";
import {ClassesList} from "./ClassesList";

export class ClassesPage extends Component<{}> {
    state = {
        showCreate: false
    }

    render() {
        return <Container className="p-3">
            <div className="toolbar">
                <Link to="/">
                    <Button icon={IconNames.ARROW_LEFT}
                            text="Назад"
                            intent={Intent.PRIMARY}
                    />
                </Link>
                <Button className="ml-3"
                        icon={IconNames.PLUS}
                        text={this.state.showCreate ? "Скрыть" : "Добавить"}
                        intent={Intent.NONE}
                        onClick={this.toggleCreate}
                />
                <LogoutButton/>
            </div>
            {this.state.showCreate && <CreateClass onCreate={this.classes.onCreate} />}
            <ClassesList ref={el => this.classes = el} />
        </Container>
    }

    toggleCreate = () => {
        this.setState({showCreate: !this.state.showCreate})
    }
}