import {Api} from "./Api";
import type {IMedia} from "../model/IMedia";

const baseUrl = '/media'

export class MediaApi {
    constructor(api: Api) {
        this.api = api
    }

    list(): Promise<IMedia[]> {
        return this.api.get(baseUrl)
    }

    upload(file: File, onUploadProgress): Promise<IMedia> {
        return this.api.upload(`${baseUrl}/upload`, file, onUploadProgress)
    }

    delete(media: IMedia): Promise {
        return this.api.delete(`${baseUrl}/${media.id}`)
    }
}