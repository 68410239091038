import {Api} from "./Api";
import {IFrame} from "../model/IFrame";

const baseUrl = '/frames'

export class FramesApi {
    constructor(api: Api) {
        this.api = api
    }

    get(id: string): Promise<IFrame> {
        return this.api.get(`${baseUrl}/${id}`)
    }

    unmark(): Promise<IFrame> {
        return this.api.get(`${baseUrl}/unmark`)
    }

    submit(id: string): Promise{
        return this.api.post(`${baseUrl}/${id}/submit`)
    }
}