import {Component} from "react";
import api from "../../api/Api";
import {Button, Classes, Spinner} from "@blueprintjs/core";
import type {IObjectClass} from "../../model/IObjectClass";
import DeleteButton from "../../components/DeleteButton";
import {toast} from "../../AppToaster";
import {IconNames} from "@blueprintjs/icons";

type State = {
    classes: IObjectClass[]
}

class ClassItem extends Component<{cl: IObjectClass}> {
    state = {
        edit: false,
        name: null,
        code: null
    }

    componentDidMount() {
        const {cl} = this.props
        this.setState({
            name: cl.name,
            code: cl.code,
        })
    }

    render() {
        const {cl} = this.props
        if (this.state.edit)
            return <>
                <input className={`${Classes.INPUT} mr-3`}
                       type="text"
                       value={this.state.name}
                       onChange={(e) => this.setState({name: e.target.value})}/>

                <input className={Classes.INPUT}
                       type="text"
                       value={this.state.code}
                       onChange={(e) => this.setState({code: e.target.value})}/>

                <Button icon={IconNames.TICK}
                        minimal
                        onClick={this.confirmEdit}
                />
                <Button icon={IconNames.CROSS}
                        minimal
                        onClick={this.toggleEdit}
                />
            </>

        return <>{cl.name} ({cl.code})
            <Button icon={IconNames.EDIT}
                    minimal
                    onClick={this.toggleEdit}
            />
        </>
    }

    toggleEdit = () => this.setState({edit: !this.state.edit})

    confirmEdit = () => {
        const {cl} = this.props
        const {name, code} = this.state

        api.classes.update(cl, name, code)
            .then(() => {
                cl.name = name
                cl.code = code
                this.setState({edit: false})
            })
    }
}

export class ClassesList extends Component<{}, State> {
    state: State = {
        classes: null
    }

    componentDidMount() {
        api.classes.list().then((classes) => {
            this.setState({classes})
        })
    }

    render() {
        const {classes} = this.state
        if (!classes) return <Spinner/>

        return <>
            {classes.map(c => <p key={c.id}><ClassItem cl={c} key={c.id} /> <DeleteButton callback={() => this.removeClass(c)}/></p> )}
        </>
    }

    onCreate = (cl: IObjectClass) => {
        const {classes} = this.state
        classes.push(cl)
        this.setState({classes})
    }

    removeClass(cl: IObjectClass) {
        api.classes.delete(cl)
            .then(() => {
                const {classes} = this.state
                const i = classes.indexOf(cl)
                if (i < 0) return
                classes.splice(i, 1)
                this.setState({classes})

                toast('Удалено')
            })
    }
}