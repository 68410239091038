import {Component} from "react";
import {Col, Container, Row} from "react-bootstrap";
import {Card, Elevation, H3, Icon} from "@blueprintjs/core";
import {IconNames} from "@blueprintjs/icons";
import {Link} from "react-router-dom";
import LogoutButton from "../components/LogoutButton";
import api from "../api/Api";

export class RootPage extends Component {
    render() {
        return <Container className="p-3 main-container">
            <div>
                <LogoutButton/>
            </div>
            <Row>
                <Col>
                    <Link to="/mark">
                        <Card interactive={true} elevation={Elevation.TWO}>
                            <div className="media">
                                <Icon icon={IconNames.WIDGET} size={50} color="#5f6b7c" className="mr-3"/>
                                <div className="media-body">Разметка</div>
                            </div>
                        </Card>
                    </Link>
                </Col>
                <Col>
                    <Link to="/media">
                        <Card interactive={true} elevation={Elevation.TWO}>
                            <div className="media">
                                <Icon icon={IconNames.VIDEO} size={50} color="#5f6b7c" className="mr-3"/>
                                <div className="media-body">Материалы</div>
                            </div>
                        </Card>
                    </Link>
                </Col>
            </Row>
            {api.me.role === 'Admin' && (<>
                <Row>
                    <Col>
                        <H3>Администрирование</H3>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Link to="/users">
                            <Card interactive={true} elevation={Elevation.TWO}>
                                <div className="media">
                                    <Icon icon={IconNames.USER} size={50} color="#5f6b7c" className="mr-3"/>
                                    <div className="media-body">Пользователи</div>
                                </div>
                            </Card>
                        </Link>
                    </Col>
                    <Col>
                        <Link to="/invites">
                            <Card interactive={true} elevation={Elevation.TWO}>
                                <div className="media">
                                    <Icon icon={IconNames.LABEL} size={50} color="#5f6b7c" className="mr-3"/>
                                    <div className="media-body">Инвайты</div>
                                </div>
                            </Card>
                        </Link>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Link to="/classes">
                            <Card interactive={true} elevation={Elevation.TWO}>
                                <div className="media">
                                    <Icon icon={IconNames.SHAPES} size={50} color="#5f6b7c" className="mr-3"/>
                                    <div className="media-body">Классы</div>
                                </div>
                            </Card>
                        </Link>
                    </Col>
                    <Col/>
                </Row>
            </>)}
        </Container>
    }
}