import {Component} from "react";
import {Container} from "react-bootstrap";
import {Button, Intent} from "@blueprintjs/core";
import {IconNames} from "@blueprintjs/icons";
import {Link} from "react-router-dom";
import {MediaList} from "./MediaList";
import LogoutButton from "../../components/LogoutButton";

export class MediaListPage extends Component<{}> {
    render() {
        return <Container className="p-3">
            <div className="toolbar">
                <Link to="/">
                    <Button icon={IconNames.ARROW_LEFT}
                            text="Назад"
                            intent={Intent.PRIMARY}
                    />
                </Link>
                <Link to="/media/upload">
                    <Button className="ml-3"
                            icon={IconNames.UPLOAD}
                            text="Загрузить"
                            intent={Intent.NONE}
                    />
                </Link>
                <LogoutButton/>
            </div>
            <MediaList />
        </Container>
    }
}