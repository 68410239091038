import {Api} from "./Api";
import type {IObjectClass} from "../model/IObjectClass";

const baseUrl = '/classes'

export class ClassesApi {
    constructor(api: Api) {
        this.api = api
    }

    list(): Promise<IObjectClass[]> {
        return this.api.get(baseUrl)
    }

    create(name: string, code: string): Promise<IObjectClass> {
        return this.api.post(baseUrl, {name, code})
    }

    update(cl: IObjectClass, name: string, code: string) {
        return this.api.post(`${baseUrl}/${cl.id}`, {name, code})
    }

    delete(cl: IObjectClass): Promise {
        return this.api.delete(`${baseUrl}/${cl.id}`)
    }
}