import {Component} from "react";
import {Button, FormGroup, InputGroup, Intent} from "@blueprintjs/core";
import {Col, Row} from "react-bootstrap";
import api from "../../api/Api";

export class CreateClass extends Component<{onCreate: () => void}> {
    state = {
        name: "",
        code: ""
    }

    render() {
        return <>
            <Row>
                <Col>
                    <FormGroup label="Наименование">
                        <InputGroup onChange={(e) => this.setState({name: e.target.value})}
                                    value={this.state.name}
                        />
                    </FormGroup>
                </Col>
                <Col>
                    <FormGroup label="Шифр">
                        <InputGroup onChange={(e) => this.setState({code: e.target.value})}
                                    value={this.state.code}
                        />
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Button text="Добавить"
                            intent={Intent.PRIMARY}
                            onClick={this.createClass}
                    />
                </Col>
            </Row>
        </>
    }

    createClass = () => {
        const {name, code} = this.state
        api.classes.create(name, code)
            .then(this.props.onCreate)
            .then(() => this.setState({name: "", code: ""}))
    }
}