import React from "react";
import {Button, Intent} from "@blueprintjs/core";
import {IconNames} from "@blueprintjs/icons";
import {toaster} from "../AppToaster";

export default function DeleteButton(props: {callback: ()=>void}) {
    const handleClick = () => {
        toaster.show({
            message: "Удалить?",
            intent: Intent.WARNING,
            action: {
                text: "Да",
                onClick: props.callback
            }
        })
    }

    return <Button icon={IconNames.TRASH}
                   intent={Intent.DANGER}
                   minimal
                   onClick={handleClick}/>
}