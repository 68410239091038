import {Component} from "react";
import {Button, Card, FormGroup, H1, InputGroup, Intent} from "@blueprintjs/core";
import {IconNames} from "@blueprintjs/icons";
import api from "../../api/Api";
import {Tooltip2} from "@blueprintjs/popover2";

export class Login extends Component<{}> {
    state = {
        showPassword: false,
        login: "",
        password: "",
        disabled: false
    }

    render() {
        const {showPassword} = this.state

        const lockButton = (
            <Tooltip2 content={`${showPassword ? "Скрыть" : "Показать"} пароль`}>
                <Button
                    icon={showPassword ? IconNames.EYE_OPEN : IconNames.EYE_OFF}
                    intent={Intent.WARNING}
                    minimal={true}
                    onClick={this.handleLockClick}
                    disabled={this.state.disabled}
                />
            </Tooltip2>
        );

        return <>
            <Card className="login-form">
                <form onSubmit={this.handleSubmit}>
                    <H1>Авторизация</H1>

                    <FormGroup label="Логин" labelFor="login-input">
                        <InputGroup id="login-input"
                                    leftIcon={IconNames.USER}
                                    disabled={this.state.disabled}
                                    value={this.state.login}
                                    onChange={e => this.setState({login: e.target.value})}
                        />
                    </FormGroup>

                    <FormGroup label="Пароль" labelFor="password-input">
                        <InputGroup id="password-input"
                                    leftIcon={IconNames.KEY}
                                    type={showPassword ? "text" : "password"}
                                    rightElement={lockButton}
                                    disabled={this.state.disabled}
                                    value={this.state.password}
                                    onChange={e => this.setState({password: e.target.value})}/>
                    </FormGroup>

                    <div className="bottom">
                        <Button text="ВХОД"
                                intent={Intent.PRIMARY}
                                type="submit"
                                loading={this.state.disabled}
                        />
                    </div>
                </form>
            </Card>
        </>
    }

    handleLockClick = () => this.setState({showPassword: !this.state.showPassword})

    handleSubmit = (event) => {
        const {login, password} = this.state

        this.setState({disabled: true})

        api.users.auth(login, password)
            .then(() => window.location.reload())
            .finally(() => this.setState({disabled: false}))

        event.preventDefault()
        return false
    }
}