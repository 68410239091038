import axios from "axios";
import {axiosToastCatch} from "../AppToaster";
import {MediaApi} from "./MediaApi";
import {ClassesApi} from "./ClassesApi";
import {FramesApi} from "./FramesApi";
import {MarkApi} from "./MarkApi";
import {UsersApi} from "./UsersApi";
import {InvitesApi} from "./InvitesApi";
import type {IMyInfo} from "../model/IMyInfo";

const dataExtract = e => e.data;

export class Api {
    me: IMyInfo = null

    constructor() {
        this.http = axios.create({
            baseURL: "/api",
            headers: {
                "Content-type": "application/json"
            }
        });

        this.media = new MediaApi(this)
        this.classes = new ClassesApi(this)
        this.frames = new FramesApi(this)
        this.mark = new MarkApi(this)
        this.users = new UsersApi(this)
        this.invites = new InvitesApi(this)
    }

    get(url: string) {
        return this.http.get(url)
            .catch(axiosToastCatch)
            .then(dataExtract)
    }

    post(url: string, data: any) {
        return this.http.post(url, data)
            .catch(axiosToastCatch)
            .then(dataExtract)
    }

    delete(url: string) {
        return this.http.delete(url)
            .catch(axiosToastCatch)
            .then(dataExtract)
    }

    upload(url: string, file: File, onUploadProgress: (progress: any) => void) {
        const formData = new FormData();
        formData.append("file", file);
        const config = {
            headers: { "Content-Type": "multipart/form-data" },
            onUploadProgress,
        }

        return this.http.post(url, formData, config)
            .catch(axiosToastCatch)
            .then(dataExtract);
    }
}

const api = new Api()

export default api