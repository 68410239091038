import {Api} from "./Api";
import type {IAnnotation} from "../model/IAnnotation";

const baseUrl = '/mark'

export class MarkApi {
    constructor(api: Api) {
        this.api = api
    }

    create(frameId: string, classId: string, x: number, y: number, w: number, h: number): Promise<IAnnotation> {
        return this.api.post(baseUrl, {frameId, classId, x, y, w, h})
    }

    forFrame(id: string): Promise<IAnnotation[]> {
        return this.api.get(`${baseUrl}/frame/${id}`)
    }

    save(ann: IAnnotation): Promise {
        return this.api.post(`${baseUrl}/${ann.id}`, {x: ann.x, y: ann.y, w: ann.w, h: ann.h})
    }

    delete(ann: IAnnotation): Promise {
        return this.api.delete(`${baseUrl}/${ann.id}`)
    }
}