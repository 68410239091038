import {BrowserRouter} from "react-router-dom";
import {FocusStyleManager} from "@blueprintjs/core";
import {AuthLoading} from "./pages/auth/AuthLoading";
import './App.scss'

FocusStyleManager.onlyShowFocusOnTabs();

function App() {
    return (
        <BrowserRouter>
            <AuthLoading/>
        </BrowserRouter>
    );
}

export default App;
