import React from "react";
import {Button} from "@blueprintjs/core";
import api from "../api/Api";

export default function LogoutButton() {
    const onClick = () => {
        api.users.logout()
            .then(() => window.location.reload())
    }

    return <Button text="Выйти" className="right-align" onClick={onClick}/>
}