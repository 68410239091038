import {Component, useEffect} from "react";
import {Route, Routes, useNavigate, useParams} from "react-router-dom";
import {Container} from "react-bootstrap";
import {Spinner} from "@blueprintjs/core";
import MarkControl from "./MarkControl";
import api from "../../api/Api";

function SelectMark() {
    const navigate = useNavigate()
    useEffect(() => {
        api.frames.unmark()
            .then((frame) => {
                navigate(`/mark/${frame.id}`)
            })
    }, [navigate])

    return <Spinner/>
}

function MarkControlPage() {
    const {id} = useParams()
    return <MarkControl frameId={id}/>
}

export class MarkRootPage extends Component<{}> {
    render() {
        return <Container className="p-3">
            <Routes>
                <Route path="/" element={<SelectMark/>}/>
                <Route path=":id" element={<MarkControlPage/>}/>
            </Routes>
        </Container>
    }
}