import {Component} from "react";
import {Route, Routes} from "react-router-dom";
import {MediaUploadPage} from "./MediaUploadPage";
import {MediaListPage} from "./MediaListPage";

export class MediaPage extends Component {
    render() {
        return <Routes>
            <Route path="/" element={<MediaListPage />} />
            <Route path="upload" element={<MediaUploadPage />} />
        </Routes>
    }
}